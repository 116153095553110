import { userNewsApi } from '@/api/news'
import { useAppStore } from '~/store/app'
import { NEWS } from '~/config/news'
import { ICategory } from '~/types/news'

export function useCategories() {
  const categoryList = ref<ICategory[]>([])
  const { $axios } = useNuxtApp()
  const { CATEGORIES } = userNewsApi()
  const { $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const route = useRoute()
  const postCategoryAlias: Ref<string | undefined> = ref(undefined)

  const getCategories = async () => {
    try {
      const categories = await $axios.get(CATEGORIES)
      if (categories && categories.data.status === 'OK') {
        categoryList.value = categories.data.data
        store.setNewsCategories(categoryList.value)
        getCurrentCategory()
        return categories.data.categories
      }
    } catch (error) {}
  }

  const getCurrentCategory = () => {
    postCategoryAlias.value = categoryList.value?.find((obj) => obj?.alias === route.params.Slug)?.alias
    if (!postCategoryAlias.value) {
      postCategoryAlias.value = categoryList.value[0].alias
    }
  }

  const onChangeCategory = (alias: string | undefined) => {
    postCategoryAlias.value = alias
    if (alias === NEWS.breadcrumb[0].alias || alias === 'tin-moi') {
      navigateTo({ path: NEWS.pathNews })
    } else {
      navigateTo({ path: `${NEWS.pathCategory}${alias}`, query: { page: 1 } })
    }
  }

  return {
    categoryList,
    postCategoryAlias,
    onChangeCategory,
    getCategories,
    getCurrentCategory
  }
}
